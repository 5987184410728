import { useDispatch, useSelector } from "react-redux";
import ClientsTable from "./components/clientsTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VuiBox from "components/VuiBox";
import { getAllClients } from "redux/actions/client";
import { useState, useEffect } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import VuiTypography from "components/VuiTypography";
import SelectTourplace from "pages/components/selectTourplace";
import { getTourplaceByISP } from "redux/actions/tourplace";

function ClientManagement() {
  const { values } = breakpoints;
  const dispatch = useDispatch();

  const [selectedTourplace, setSelectedTourplace] = useState();
  const userdata = useSelector((state) => state.auth.userData);
  const selectTourPlace = useSelector((state) => state.tourplaceReducer.tourplaceData);
  const tourplacedata = selectTourPlace.map((item) => ({ value: item.id, label: item.place_name }))

  const access_token = userdata.access

  const param = {
    start_row_index: 0,
    end_row_index: 9
  }

  useEffect(() => {
    param.tourplace = selectedTourplace;
    dispatch(getAllClients(userdata.access, param));
    dispatch(getTourplaceByISP(access_token));
  }, [])

  useEffect(() => {
    param.tourplace = selectedTourplace;
    dispatch(getAllClients(access_token, param))
  }, [selectedTourplace])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox p={1}>
        <VuiTypography
          variant={window.innerWidth < values.sm ? "h3" : "h2"}
          textTransform="capitalize"
          fontWeight="bold"
          color="white"
        >
          Client Program
        </VuiTypography>
      </VuiBox>
      <VuiBox py={3}>
        <SelectTourplace tourplacedata={tourplacedata} setTourPlace={setSelectedTourplace} />
        <ClientsTable tourplace={selectedTourplace}/>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ClientManagement;

export default {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    APPROVED: 'APPROVED',
    CANCELED: 'CANCELED',
    VOIDED: 'VOIDED',
    REFUNDED: 'REFUNDED',
    DECLINED: 'DECLINED',
    INSUFFCIENT_FUNDS: 'INSUFFCIENT_FUNDS',
    CARD_EXPIRED: 'CARD_EXPIRED',
    CARD_DECLINED: 'CARD_DECLINED',
    INVALID_CARD: 'INVALID_CARD',
    FRAUD_DETECTED: 'FRAUD_DETECTED',
    UNKNOWN: 'unknown',
    VERSION_EXPIRES: 'VERSION_EXPIRES',
}
  
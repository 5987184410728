import { useEffect, useState } from "react";
import { alert_proxy_not_working } from "redux/actions/warningMsgFunc";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardHeader, Typography, Divider } from '@mui/material';
import { Grid } from "@mui/material";
import { uploadClientVideoFile } from "redux/actions/tour_management";
import ReactiveButton from "reactive-button";
import { getAllPricing } from "redux/actions/pricing";
import Hls from "hls.js";
import VuiSelect from "components/VuiSelect";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Chip } from "@mui/material";
import Icon from "@mui/material/Icon";
import { alert_select_version } from "redux/actions/warningMsgFunc";
import { alert_cannot_record } from "redux/actions/warningMsgFunc";
import { alert_tourplace_select_error } from "redux/actions/warningMsgFunc";
var mediaRecorder = null;
var recordedChunks = [];

const startRecording = (access_token, cameraName, videoFileName, recording_time, tourplace_id, priceID, setRecordingStatus, setRecordingSeconds, dispatch) => {
    try {
        const video = document.getElementById(cameraName);
        const stream = video.captureStream();
        mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm; codecs=vp9' });
        mediaRecorder.ondataavailable = function (event) {
            if (event.data.size > 0) {
                recordedChunks.push(event.data);
            }
        };
        mediaRecorder.onstop = async function () {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });
            recordedChunks = [];
            const formData = new FormData();
            await formData.append('video_path', blob, videoFileName);
            await formData.append('tourplace_id', tourplace_id);
            await formData.append('pricing_id', priceID);
            dispatch(uploadClientVideoFile(access_token, formData));
            setRecordingStatus(recording_button_status_events.SUCCESS)
        };
        mediaRecorder.start();
        setRecordingStatus(recording_button_status_events.LOADING);
        setRecordingSeconds(recording_time);
        const countdown = setInterval(() => {
            setRecordingSeconds(prev => {
                if (prev === 1) {
                    clearInterval(countdown);
                    stopRecording();
                }
                return prev - 1;
            });
        }, 1000);
    } catch (error) {
        console.log(error)
        dispatch(alert_proxy_not_working())
    }
}

const stopRecording = async () => {
    if (mediaRecorder) {
        await mediaRecorder.stop();
    }
}

const recording_button_status_events = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
}

const CustomOption = ({ entry, valid_data, user_type }) => {
    const valid_item = valid_data.find(item => item.price_id === entry.id);
    return (
        <VuiBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {user_type !== 3 || valid_item ?
                <VuiTypography variant={'caption'} color={'white'} sx={{ alignContent: 'center' }}>{`${entry.record_time} S / ${entry.price}$`}</VuiTypography> :
                <VuiTypography variant={'caption'} color={'white'} sx={{ alignContent: 'center', textDecoration: 'line-through', textDecorationColor: 'red' }}>{`${entry.record_time} S / ${entry.price}$`}</VuiTypography>
            }
            {user_type !== 3 || valid_item && <StatusComponent item={valid_item} />}
        </VuiBox>
    )
}

const StatusComponent = ({ item }) => {
    return <Chip color={'success'} size={'small'} icon={<Icon fontSize={'small'} color="error">favorite</Icon>} label={`reamin : ${item.remain}`} />
}

const CameraItem = ({ camera_data, id, pricing_data, valid_price, selectedTourplace }) => {
    const dispatch = useDispatch();
    const userdata = useSelector((state) => state.auth.userData);
    const [recordingStatus, setRecordingStatus] = useState(recording_button_status_events.IDLE);
    const hlsurl = `${process.env.REACT_APP_BASE_URL}/${camera_data.output_url}/index.m3u8`;

    const activeStreams = {};
    const camera_name = `camera_${id}`
    const access_token = userdata.access;
    const user_type = userdata.usertype;
    const tourplace_id = userdata.tourplace
    const [recordingSeconds, setRecordingSeconds] = useState();
    const [priceID, setPriceID] = useState();

    const options = pricing_data.map((item) => (
        {
            value: item.id,
            label: <CustomOption entry={item} valid_data={valid_price} user_type={user_type}/>,
            isDisabled: user_type === 3 && !valid_price.find((valid_item) => valid_item.price_id === item.id)
        })
    );

    useEffect(async () => {
        try {
            await waitForStream();
            addStreamPlayer();
        } catch (error) {
            dispatch(alert_proxy_not_working())
        }
    }, [])

    useEffect(() => {
        return () => {
            stopStream();
        };
    }, [])

    const stopStream = async () => {
        try {
            if (activeStreams[camera_name]) {
                activeStreams[camera_name].stop = true;
                if (activeStreams[camera_name].hls) {
                    activeStreams[camera_name].hls.destroy();
                }
                delete activeStreams[camera_name];
            }
            const video = document.getElementById(camera_name);
            if (video) {
                streamsDiv.removeChild(video);
            }
        } catch (error) {
            dispatch(alert_proxy_not_working())
        }
    }

    const waitForStream = async () => {
        const videoSrc = hlsurl
        activeStreams[camera_name] = { stop: false };
        while (true) {
            if (activeStreams[camera_name].stop) {
                return;
            }
            try {
                const response = await fetch(videoSrc, { method: 'HEAD' });
                if (response.ok) {
                    return;
                }
            } catch (error) {
                console.error('Error checking stream:', error);
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    const addStreamPlayer = () => {
        const video = document.getElementById(camera_name);
        const videoSrc = hlsurl;
        if (Hls.isSupported()) {
            const hls = new Hls();
            activeStreams[camera_name].hls = hls;
            hls.loadSource(videoSrc);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, function () {
                video.play();
            });
            hls.on(Hls.Events.ERROR, function (event, data) {
                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            console.error("fatal network error encountered, try to recover");
                            hls.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.error("fatal media error encountered, try to recover");
                            hls.recoverMediaError();
                            break;
                        default:
                            hls.destroy();
                            break;
                    }
                }
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = videoSrc;
            video.addEventListener('canplay', function () {
                video.play();
            });
        }
    }

    const cardHeader = (header) => {
        return <Typography alignSelf={'center'} color={"whitesmoke"}>{header}</Typography>
    }

    const onChangeStartBtn = () => {
        var fileName = `${Date.now()}.webm`;
        if (priceID !== null) {
            const recording_time = pricing_data.find(item => item.id === priceID)?.record_time;
            const tourplace_for_upload_video = user_type === 3 ? tourplace_id : selectedTourplace;
            if(tourplace_for_upload_video !== undefined){
                if (recording_time > 0) {
                    startRecording(access_token, camera_name, fileName, recording_time, tourplace_for_upload_video, priceID, setRecordingStatus, setRecordingSeconds, dispatch)
                } else {
                    dispatch(alert_select_version())
                }
            }else{
                dispatch(alert_tourplace_select_error())
            }
        } else {
            dispatch(alert_cannot_record())
        }
    }

    const handleOnChange = (e) => {
        setPriceID(e.value)
    }
    return (
        <Grid item xs={12} sm={6} lg={4} key={id} mt={8}>
            <Card>
                <CardHeader component={() => cardHeader(camera_data.camera_name)} />
                <Divider light sx={{ marginTop: 5 }} />
                <CardContent>
                    <video controls autoPlay id={camera_name} style={{ width: '-webkit-fill-available' }}></video>
                    <VuiBox sx={{ marginBottom: '10px' }}>
                        <VuiSelect
                            options={options}
                            size="small"
                            onChange={handleOnChange}
                        />
                    </VuiBox>
                    <ReactiveButton
                        buttonState={recordingStatus}
                        onClick={onChangeStartBtn}
                        idleText={'Start Recording'}
                        successText={'Recording Completed!'}
                        loadingText={`Recording ${recordingSeconds} S`}
                        rounded
                        shadow
                        outline
                        block color="blue"
                    />
                </CardContent>
            </Card>
        </Grid>

    );
};

export default CameraItem;
